<!-- Created by henian.xu on 2019/10/18. -->

<template>
  <Page>
    <Container ref="container">
      <XForm
        ref="form"
        :model="formModel"
        :rules="formRules"
      >
        <!--<FormItem
                    label="店铺名称"
                    prop="storeName"
                >
                    <XInput
                        ref="storeNameInput"
                        v-model="formModel.storeName"
                    />
                </FormItem>-->
        <FormItem label="页头类型">
          <Selector
            tiling
            :data="tplData"
            v-model="formModel.bannerType"
          />
        </FormItem>
        <upload
          v-show="false"
          ref="uploadAdImage"
          action="/rpc/comm/rs/attachment/uploadFileLibrary"
          accept="image/*"
          v-model="formModel.storeAdImage"
          :max-files="1"
          :column="2"
        />
        <upload
          v-show="false"
          ref="uploadLogo"
          action="/rpc/comm/rs/attachment/uploadFileLibrary"
          accept="image/*"
          v-model="formModel.storeLogo"
          :max-files="1"
          :column="2"
        />

        <UploadCustomerServiceImage
          ref="uploadCustomerServiceImage"
          v-model="formModel.customerServiceImage"
        />
        <template v-if="+formModel.bannerType === 1">
          <div class="store-banner type1">
            <div
              class="banner"
              @click="onUpAdImage"
            >
              <div
                class="bg"
                :style="bannerStyles"
              />
              <Button
                class="customer-service-image"
                radius="all"
                theme="main"
                @click.stop="onUploadCustomerServiceImage"
              >
                上传客服二维码
              </Button>
            </div>
            <div class="inner">
              <div
                class="logo"
                :style="logoStyles"
                @click="onUpLogo"
              />
              <div class="info">
                <div class="customer-service">
                                <!--customer-service-->&nbsp;
                </div>
                <div class="store-name">
                  <!--@click.stop="onStoreName"-->
                  <input
                    type="text"
                    v-model="formModel.storeName"
                    placeholder="请输入店铺名称"
                  >
                  <!--{{ formModel.storeName||'请输入店铺名称' }}-->
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="+formModel.bannerType === 2">
          <div class="store-banner type2">
            <div
              class="banner"
              @click="onUpAdImage"
            >
              <div
                class="bg"
                :style="bannerStyles"
              />
              <Button
                class="customer-service-image"
                radius="all"
                theme="main"
                @click.stop="onUploadCustomerServiceImage"
              >
                上传客服二维码
              </Button>
              <div class="inner">
                <div
                  class="logo"
                  :style="logoStyles"
                  @click.stop="onUpLogo"
                />
                <div class="info">
                  <div
                    class="store-name"
                    @click.stop="()=>{}"
                  >
                    <input
                      type="text"
                      v-model="formModel.storeName"
                      placeholder="请输入店铺名称"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="+formModel.bannerType === 3">
          <div class="store-banner type3">
            <div
              class="banner"
              @click="onUpAdImage"
            >
              <div
                class="bg"
                :style="bannerStyles"
              />
              <Button
                class="customer-service-image"
                radius="all"
                theme="main"
                @click.stop="onUploadCustomerServiceImage"
              >
                上传客服二维码
              </Button>
              <div class="inner">
                <div
                  class="logo"
                  :style="logoStyles"
                  @click="onUpLogo"
                />
                <div class="info">
                  <div
                    class="store-name"
                    @click.stop="()=>{}"
                  >
                    <input
                      type="text"
                      v-model="formModel.storeName"
                      placeholder="请输入店铺名称"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <FormItem label="列表类型">
          <Selector
            tiling
            :data="[{id:1,name:'单列'},{id:2,name:'双列'}]"
            v-model="formModel.goodsListType"
          />
        </FormItem>
        <template>
          <div :class="['goods-list-skeleton',`type${formModel.goodsListType}`]">
            <div
              class="item"
              v-for="item in 2"
              :key="item"
            >
              <div class="inner">
                <div class="img-obj">
                  <div class="inner" />
                </div>
                <div class="inner">
                  <div class="label">
                                        &nbsp;
                  </div>
                  <div class="desc">
                                        &nbsp;
                  </div>
                  <div class="price_">
                                        &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <FormItem label="分类类型">
          <Selector
            tiling
            :data="[{id:1,name:'顶部'},{id:2,name:'左则'}]"
            v-model="formModel.catListType"
          />
        </FormItem>
        <template>
          <div :class="['cat-list-skeleton',`type${formModel.catListType}`]">
            <div class="cat-list">
              <div
                class="item"
                v-for="item in 5"
                :key="item"
              >
                                &nbsp;
              </div>
            </div>
            <div :class="['goods-list-skeleton',{type1:+formModel.catListType===2},{type2:+formModel.catListType===1}]">
              <div
                class="item"
                v-for="item in 2"
                :key="item"
              >
                <div class="inner">
                  <div class="img-obj">
                    <div class="inner" />
                  </div>
                  <div class="inner">
                    <div class="label">
                                            &nbsp;
                    </div>
                    <div class="desc">
                                            &nbsp;
                    </div>
                    <div class="price_">
                                            &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!--<FormItem
                    label="客服二维码"
                    prop="storeName"
                >
                    <upload
                        ref="uploadCustomerServiceImage"
                        class="npa-a kf-qrcode"
                        action="/rpc/comm/rs/attachment/uploadFileLibrary"
                        accept="image/*"
                        v-model="formModel.customerServiceImage"
                        :max-files="1"
                        :column="2"
                    />
                </FormItem>-->
      </XForm>
    </Container>
    <PageBar>
      <Button
        theme="main"
        @click="()=>{onSubmit()}"
      >
        保存
      </Button>
    </PageBar>
  </Page>
</template>

<script>
import { Buyer } from '@/api';
import UploadCustomerServiceImage from 'pages/proxyStore/_uploadCustomerServiceImage';
export default {
  name: 'Decoration',
  components: { UploadCustomerServiceImage },
  data() {
    return {
      formModel: {
        bannerType: '1',
        goodsListType: '1',
        catListType: '1',
        storeName: '',
        customerServiceImage: [],
        storeAdImage: [],
        storeLogo: [],
      },
      formRules: {
        //门店名称
        storeName: {
          required: true,
          message: '店铺名称不能为空',
        },
        //客服二维码
        /*customerServiceImage: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '客服二维码不能为空',
                },*/
      },
      tplData: [
        {
          id: 1,
          name: '模板一',
        },
        {
          id: 2,
          name: '模板二',
        },
        {
          id: 3,
          name: '模板三',
        },
      ],
      storeDetail: {},
    };
  },
  async beforeRouteEnter(to, from, next) {
    const {
      data: { data: detail },
    } = await Buyer.Sp.ShopStore.getShopStoreDetail();
    next(vm => {
      vm.storeDetail = detail;
      vm.restoreForm();
    });
  },
  computed: {
    /**--**/
    storeAdImage() {
      const {
        formModel: { storeAdImage },
      } = this;
      return storeAdImage.length ? storeAdImage[0].address : '';
    },
    storeLogo() {
      const {
        formModel: { storeLogo },
      } = this;
      return storeLogo.length ? storeLogo[0].address : '';
    },
    bannerStyles() {
      const { storeAdImage } = this;
      if (!storeAdImage) return {};
      return {
        backgroundColor: 'transparent',
        backgroundImage: `url(${storeAdImage})`,
      };
    },
    logoStyles() {
      const { storeLogo } = this;
      if (!storeLogo) return {};
      return {
        backgroundColor: 'transparent',
        backgroundImage: `url(${storeLogo})`,
      };
    },
  },
  methods: {
    restoreForm() {
      const { storeDetail, formModel } = this;
      const { storeDecorationJson } = storeDetail;
      const storeDecoration = storeDecorationJson
        ? JSON.parse(storeDecorationJson)
        : {};
      Object.keys(formModel).forEach(key => {
        if (key === 'storeLogo') {
          formModel[key] = storeDetail[key]
            ? [
                {
                  name: storeDetail[key],
                  address: storeDetail.storeLogoUrl,
                },
              ]
            : [];
        } else if (key === 'storeAdImage') {
          formModel[key] = storeDetail[key]
            ? [
                {
                  name: storeDetail[key],
                  address: storeDetail.storeAdImageUrl,
                },
              ]
            : [];
        } else if (key === 'customerServiceImage') {
          formModel[key] = storeDetail[key]
            ? [
                {
                  name: storeDetail[key],
                  address: storeDetail.customerServiceImageUrl,
                },
              ]
            : [];
        } else {
          formModel[key] = storeDecoration[key] || formModel[key];
          formModel[key] = storeDetail[key] || formModel[key];
        }
      });
    },
    onUpAdImage() {
      this.$refs['uploadAdImage'].selectFile();
    },
    onUpLogo() {
      this.$refs['uploadLogo'].selectFile();
    },
    onUploadCustomerServiceImage() {
      this.$refs['uploadCustomerServiceImage'].open();
    },
    async onSubmit() {
      //await this.$refs['form'].validate();
      const { formModel } = this;
      if (!formModel.storeName) {
        this.$messageBox.tips('请输入店铺名称');
        return;
      }
      if (!formModel.storeAdImage.length) {
        this.$messageBox.tips('请上传门眉');
        return;
      }
      if (!formModel.storeLogo.length) {
        this.$messageBox.tips('请上传店铺logo');
        return;
      }
      if (!formModel.customerServiceImage.length) {
        this.$messageBox.tips('请上传客服二维码！');
        return;
      }
      const storeDecorationJson = JSON.stringify({
        bannerType: formModel.bannerType,
        goodsListType: formModel.goodsListType,
        catListType: formModel.catListType,
      });
      const params = {
        ...formModel,
        customerServiceImage: formModel.customerServiceImage
          .reduce((p, c) => (p.push(c.name), p), [])
          .join(','),
        storeAdImage: formModel.storeAdImage
          .reduce((p, c) => (p.push(c.name), p), [])
          .join(','),
        storeLogo: formModel.storeLogo
          .reduce((p, c) => (p.push(c.name), p), [])
          .join(','),
        storeDecorationJson,
      };
      console.log(params);
      const {
        data: { msg },
      } = await this.$api.Buyer.Sp.ShopStore.saveShopStoreDecoration(params);
      this.$messageBox.tips(msg);
      this.$router.go(-1);
    },
    onStoreName() {
      this.$refs['storeNameInput'].$refs['input'].focus();
    },
  },
};
</script>

<style lang="scss">
@mixin changeBg {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: $padding-small;
    right: $padding-small;
    bottom: $padding-small;
    left: $padding-small;
    border: 3px dotted #fff;
    opacity: 0.5;
  }
  &:after {
    content: '更改';
    position: absolute;
    bottom: $padding;
    right: $padding-big;
    color: #fff;
  }
}
.store-banner {
  > .banner {
    position: relative;
    width: 100%;
    > .bg {
      width: 100%;
      padding-top: 30%;
      background: $gray2 center no-repeat;
      background-size: cover;
      @include changeBg;
    }
    > .customer-service-image {
      position: absolute;
      z-index: $z-index-2;
      right: $padding-small;
      top: $padding-small;
    }
  }

  .store-name {
    > input {
      outline: none;
      height: 100%;
      width: 100%;
      background-color: transparent;
    }
  }
  &.type1 {
    background-color: #fff;
    padding-bottom: $padding-big;
    > .inner {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 0 $padding-big;
      //transform: translateY(-50%);
      margin-top: -0.8rem;
      pointer-events: none;
      > .logo {
        flex: 0 0 auto;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: $padding-small;
        margin-right: $margin;
        background: $gray3 center no-repeat;
        background-size: cover;
        pointer-events: auto;
        @include changeBg;
      }
      > .info {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        > .store-name {
          font-size: 120%;
          font-weight: bold;
          line-height: 0.6rem;
          pointer-events: auto;
          @include text-line(1);
        }
        > .customer-service {
          line-height: 0.6rem;
          @include text-line(1);
        }
      }
    }
  }
  &.type2 {
    background-color: #fff;
    padding-bottom: $padding-big;
    > .banner {
      position: relative;
      > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 $padding-big;
        //transform: translateY(-50%);
        > .logo {
          flex: 0 0 auto;
          width: 1.6rem;
          height: 1.6rem;
          border-radius: $padding-small;
          margin-right: $margin;
          background: $gray3 center no-repeat;
          background-size: cover;
          @include changeBg;
        }
        > .info {
          flex: 1 1 1%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          > .store-name {
            font-size: 120%;
            font-weight: bold;
            line-height: 0.6rem;
            @include text-line(1);
            background-color: rgba(255, 255, 255, 0.9);
            display: inline-block;
            border-radius: 100vw;
            padding: 0 $padding;
          }
          > .customer-service {
            line-height: 0.6rem;
            @include text-line(1);
          }
        }
      }
    }
  }
  &.type3 {
    background-color: #fff;
    padding-bottom: $padding-big;
    > .banner {
      position: relative;
      > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0 $padding-big;
        //transform: translateY(-50%);
        > .logo {
          flex: 0 0 auto;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: $padding-small;
          background: $gray3 center no-repeat;
          background-size: cover;
          @include changeBg;
        }
        > .info {
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          > .store-name {
            font-size: 110%;
            font-weight: bold;
            line-height: 0.6rem;
            @include text-line(1);
            background-color: rgba(255, 255, 255, 0.9);
            display: inline-block;
            border-radius: 100vw;
            padding: 0 $padding;
          }
          > .customer-service {
            line-height: 0.6rem;
            @include text-line(1);
          }
        }
      }
    }
  }
}
.goods-list-skeleton {
  &.type1 {
    > .item {
      + .item {
        margin-top: $margin;
      }
      > .inner {
        padding: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        background-color: #fff;
        > .img-obj {
          flex: 0 0 auto;
          width: 2rem;
          margin-right: $margin-small;
          > .inner {
            width: 100%;
            padding-top: 100%;
            background-color: $gray2;
          }
        }
        > .inner {
          flex: 1 1 1%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          > .price_,
          > .desc,
          > .label {
            background-color: $gray2;
            margin-top: $margin-small;
          }
          > .label {
            margin-top: 0;
          }
          > .desc {
            width: 80%;
          }
          > .price_ {
            width: 50%;
          }
        }
      }
    }
  }
  &.type2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: $padding-small;
    > .item {
      width: 50%;
      padding: $padding-small;
      + .item {
        margin-top: 0;
      }
      > .inner {
        padding: $padding;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        background-color: #fff;
        > .img-obj {
          flex: 0 0 auto;
          width: 100%;
          > .inner {
            width: 100%;
            padding-top: 100%;
            background-color: $gray2;
          }
        }
        > .inner {
          flex: 1 1 1%;
          > .price_,
          > .desc,
          > .label {
            background-color: $gray2;
            margin-top: $margin-small;
          }
          > .desc {
            width: 80%;
          }
          > .price_ {
            width: 50%;
          }
        }
      }
    }
  }
}
.cat-list-skeleton {
  &.type1 {
    > .cat-list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      padding: $padding;
      background-color: #fff;

      > .item {
        width: 2rem;
        background-color: $gray2;
        + .item {
          margin-left: $margin;
        }
      }
    }
  }
  &.type2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    > .cat-list {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: $padding;
      background-color: #fff;

      > .item {
        width: 1.2rem;
        background-color: $gray2;
        + .item {
          margin-top: $margin;
        }
      }
    }
    > .goods-list-skeleton {
      flex: 1 1 1%;
    }
  }
}
</style>

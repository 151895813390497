<!-- Created by henian.xu on 2019/3/4. -->

<template>
  <Page>
    <Container ref="container">
      <!--{{ selectList }}-->
      <List v-if="goodsList && goodsList.length">
        <ProxyStoreSelectedItem
          class="ma-b"
          v-for="(item,index) in goodsList"
          :key="item.id"
          :data="item"
          v-model="selectList"
          @click="onItem(item,index)"
          @delete="onDel(item,index)"
          @editSuccess="onEditSuccess($event,item)"
        />
      </List>
      <ProxyStoreGoodsPicker
        class="pa-a"
        ref="GoodsPicker"
        @update="onGoodsUpdate"
        @close="onGoodsPopupClose"
        v-model="goodsIds"
      />
    </Container>
    <PageBar>
      <ProxyStoreBatchEditGoods
        is-add
        :cat-select-list="catSelectList"
        :disabled="!canBatch"
        @input="onBatchEditInput"
      >
        <Button :theme="canBatch?'second':'g5'">
          批量设置<span v-if="selectList.length">({{ selectList.length }})</span>
        </Button>
      </ProxyStoreBatchEditGoods>
      <Button
        label="提交"
        theme="main"
        @click="()=>{onSubmit()}"
      />
    </PageBar>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import { Buyer } from '@/api';

export default {
  name: 'ProxyStoreSelectGoods',
  mixins: [storeMixin],
  data() {
    return {
      ids: '',
      goodsList: [],
      goodsIds: [],
      selectList: [],
      catSelectList: [], // 分类选择器列表
    };
  },
  beforeRouteEnter(to, from, next) {
    Buyer.Sp.ShopStoreGoodsCat.select().then(json => {
      const res = json.data.data || [];
      next(vm => {
        vm.catSelectList = res.reduce(
          (p, c) => (p.push({ id: c.id, name: c.goodsCatName }), p),
          [],
        );
      });
    });
  },
  computed: {
    canBatch() {
      return !!this.selectList.length;
    },
  },
  methods: {
    onGoodsUpdate(ids) {
      this.ids = ids;
      this.getGoodsDetail(ids.join(','));
    },
    getGoodsDetail(ids) {
      this.$nprogress.start();
      this.$api.Buyer.Sp.ShopStoreGoods.agentGoodsDetail(ids)
        .then(json => {
          let res = json.data.data;
          if (!Array.isArray(res)) {
            res = [res];
          }
          this.selectList = [];
          this.goodsList = res.map(item => {
            this.selectList.push(item.goods.id);
            return {
              ...item,
              catSelectList: this.catSelectList, // 为了展示当前已选择的分类
              formModel: {
                id: item.goods.id, //商品ID
                goodsCatIds: [], //商品类目ID 逗号分隔
                goodsStyleSource: '1', //素材类型 1默认风格 2商品风格库 3自定义风格
                goodsStyleId: '', //商品风格ID
                goodsName: '', //商品名称
                goodsSubtitle: '', //商品副标题
                goodsImage: [], //商品列表图
                goodsDetailImage: [], //商品详情图
                // skuJson: '', //商品json {skuId:'123',addPrice:'123'}
                skuJson: JSON.stringify(
                  item.goods.skuList.reduce((p, c) => {
                    p.push({
                      skuId: c.skuId,
                      addPrice: c.addPrice || 0,
                      price: c.price || 0,
                    });
                    return p;
                  }, []),
                ), //商品json {skuId:'123',addPrice:'123'}
              },
            };
          });
          console.log(res);
        })
        .finally(() => this.$nprogress.done());
    },
    // 批量操作处理
    onBatchEditInput(data) {
      console.log({ ...data });
      const { goodsList, selectList } = this;
      const { addPriceType, addPrice } = data;
      const selectListMap = selectList.reduce((p, c) => ((p[c] = c), p), {});
      goodsList.forEach(item => {
        const { formModel, goods } = item;
        if (!selectListMap[goods.id]) return; // 未选中的直接跳过
        Object.keys(data).forEach(key => {
          if (key === 'addPriceType') return;
          if (key === 'addPrice') {
            formModel.skuJson = this.batchEditSkuJson(
              formModel.skuJson,
              addPrice,
              addPriceType === 2,
            );
          } else {
            formModel[key] = data[key];
          }
        });
      });
    },
    // 批量更新 skuJson
    batchEditSkuJson(json = '[]', addPrice = 0, isScale = false) {
      json = JSON.parse(json);
      json.forEach(item => {
        if (isScale) {
          item.addPrice = item.price * (addPrice / 100);
        } else {
          item.addPrice = addPrice;
        }
      });
      return JSON.stringify(json);
    },
    onItem(item) {
      item = item.goods;
      const { selectList } = this;
      const index = selectList.indexOf(item.id);
      if (index === -1) {
        this.selectList.push(item.id);
      } else {
        this.selectList.splice(index, 1);
      }
    },
    onDel(item, index) {
      const index_ = this.selectList.indexOf(item.goods.id);
      if (index_ !== -1) {
        // 删除已选择中的
        this.selectList.splice(index_, 1);
      }
      this.goodsList.splice(index, 1);
    },
    async onSubmit() {
      this.goodsList.map(item => {
        const { formModel } = item;
        const goodsCatIds = formModel.goodsCatIds.join(',');
        return {
          ...formModel,
          goodsCatIds,
          goodsImage: formModel.goodsImage
            .reduce((p, c) => (p.push(c.name), p), [])
            .join(','),
          goodsDetailImage: formModel.goodsDetailImage
            .reduce((p, c) => (p.push(c.name), p), [])
            .join(','),
        };
      });

      const { goodsList } = this;
      const len = goodsList.length;
      const data = [];
      for (let i = 0; i < len; i++) {
        const item = goodsList[i];
        const { formModel } = item;
        const goodsCatIds = formModel.goodsCatIds.join(',');
        if (!goodsCatIds) {
          this.$messageBox.alert(`请设置商品【${item.goods.goodsName}】的分类`);
          // break;
          return;
        }
        data.push({
          ...formModel,
          goodsCatIds,
          goodsImage: formModel.goodsImage
            .reduce((p, c) => (p.push(c.name), p), [])
            .join(','),
          goodsDetailImage: formModel.goodsDetailImage
            .reduce((p, c) => (p.push(c.name), p), [])
            .join(','),
        });
      }

      console.log(JSON.stringify(data));
      const res = await this.$api.Buyer.Sp.ShopStoreGoods.batchSaveAgentGoods(
        JSON.stringify(data),
      );
      console.log(res);
      this.$router.go(-1);
    },
    onEditSuccess(data, item) {
      console.log('onEditSuccess', data);
      item.formModel = data;
    },
    onGoodsPopupClose() {
      if (this.ids || (this.goodsList && this.goodsList.length)) return;
      this.$router.go(-1);
      console.log(1111111111111111111111);
    },
  },
  created() {
    // 测试用
    //this.onGoodsUpdate([46, 28 /*, 27, 26*/]);
  },
  mounted() {
    this.$refs['GoodsPicker'].open();
  },
};
</script>

<style lang="scss">
</style>

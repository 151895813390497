<!-- Created by henian.xu on 2019/3/4. -->

<template>
  <Page>
    <Container ref="container">
      <div class="achievement-wrap ma-b">
        <div class="header">
          <div class="label">
            今日业绩
          </div>
          <div
            class="extra"
            @click="()=>{onStoreQrcode()}"
          >
            店铺二维码 <XIcon content="f10a" />
          </div>
        </div>
        <div class="body">
          <div class="item">
            <div class="label">
              支付订单数
            </div>
            <div class="value">
              {{ storeDetail.orderNum }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              支付订单金额
            </div>
            <div class="value">
              {{ storeDetail.orderAmount | price }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              预期提成
            </div>
            <div class="value">
              {{ storeDetail.agentAmount | price }}
            </div>
          </div>
        </div>
      </div>

      <!--<StoreHeader :data="storeDetail" />-->

      <list>
        <list-item class="npa-b">
          <div
            class="label fw-bold fs-big"
            slot="label"
          >
            店铺余额
          </div>
        </list-item>
      </list>
      <div class="tool-bar ma-b">
        <Linker
          class="item"
          to="./settlement/20"
        >
          <div class="num price">
            {{ (storeDetail.totalIncome + storeDetail.frozenAmount)||0 | price }}
          </div>
          <div class="label">
            累计提成
          </div>
        </Linker>
        <Linker
          to="./settlement/10"
          class="item"
        >
          <div class="num price">
            {{ storeDetail.frozenAmount||0 | price }}
          </div>
          <div class="label">
            冻结金额
          </div>
        </Linker>
        <Linker
          to="./collect"
          class="item"
        >
          <div class="num price">
            {{ ((storeDetail.totalIncome - storeDetail.cashedAmount - storeDetail.cashingAmount)||0) | price }}
          </div>
          <div class="label">
            账户金额
          </div>
          <span class="badge badge-second fs-little1">提现</span>
        </Linker>
      </div>

      <list>
        <list-item to="./order/list/0">
          <div
            class="label fw-bold fs-big"
            slot="label"
          >
            订单管理
          </div>
          <div
            class="extra fs-little tc-g6"
            slot="extra"
          >
            查看全部订单
          </div>
        </list-item>
      </list>
      <cube-toolbar class="border-cube-toolbar small ma-b">
        <linker
          to="./order/list/10"
          class="item"
        >
          <XIcon
            content="f130"
            :badge="orderCount.waitPayNum"
          />
          <div class="label">
            待付款
          </div>
        </linker>
        <linker
          to="./order/list/20"
          class="item"
        >
          <XIcon
            content="f132"
            :badge="orderCount.waitShipNum"
          />
          <div class="label">
            待发货
          </div>
        </linker>
        <linker
          to="./order/list/30"
          class="item"
        >
          <XIcon
            content="f134"
            :badge="orderCount.waitRecNum"
          />
          <div class="label">
            待收货
          </div>
        </linker>
        <linker
          to="./order/list/100"
          class="item"
        >
          <XIcon content="f147" />
          <!--:badge="orderCount.completedNum" -->
          <div class="label">
            已完成
          </div>
        </linker>
        <!--<linker
                    to="./order/list/99"
                    class="item"
                >
                    <i class="f-icon">
                        &#xf054;
                        <em v-if="orderCount.canceledNum">{{ orderCount.canceledNum }}</em>
                    </i>
                    <div class="label">
                        已取消
                    </div>
                </linker>-->
        <linker
          class="item npa-l npa-r"
          to="/refund/list?isShopStore"
        >
          <XIcon
            content="f124"
            :badge="orderCount.waitRefundNum"
          />
          <div class="label">
            退款/售后
          </div>
        </linker>
      </cube-toolbar>

      <list>
        <list-item>
          <div
            class="label fw-bold fs-big"
            slot="label"
          >
            店铺管理
          </div>
        </list-item>
      </list>
      <GridGroup
        :columns="3"
        class="bc-w ma-b"
        :grid-line="false"
      >
        <Grid
          label="店铺装修"
          to="./decoration"
        >
          <XIcon
            slot="icon"
            content="f14a"
            size="130%"
            svg
          />
        </Grid>
        <Grid
          label="我的店铺"
          @click="onGotoStore"
        >
          <XIcon
            slot="icon"
            content="f11d"
            size="130%"
            svg
          />
        </Grid>
        <Grid label="发货信息">
          <XIcon
            slot="icon"
            content="f142"
            size="130%"
            @click="$refs['proxyStoreEditSendInfo'].open()"
            svg
          />
          <ProxyStoreEditSendInfo
            ref="proxyStoreEditSendInfo"
            class="label lh-normal"
            slot="label"
            :init-data="storeDetail"
            @success="onEditSendSuccess"
          >
            发货信息
          </ProxyStoreEditSendInfo>
        </Grid>
        <Grid label="一键开店">
          <XIcon
            slot="icon"
            content="f144"
            size="130%"
            @click="$refs['proxyStoreBatchEditGoods'].open()"
            svg
          />
          <ProxyStoreBatchEditGoods
            ref="proxyStoreBatchEditGoods"
            class="label lh-normal"
            slot="label"
            @input="(data)=>{onBatchEditInput(data)}"
          >
            一键开店
          </ProxyStoreBatchEditGoods>
        </Grid>
        <Grid
          label="类目管理"
          to="./category"
        >
          <XIcon
            slot="icon"
            content="f140"
            size="130%"
            svg
          />
        </Grid>
        <Grid
          label="商品管理"
          to="./goods"
        >
          <XIcon
            slot="icon"
            content="f122"
            size="130%"
            :badge="unAgentGoodsNum"
            svg
          />
        </Grid>
      </GridGroup>

      <canvas
        class="canvas"
        v-show="false"
        ref="graphicCanvas"
        width="500"
        height="600"
      />
    </Container>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import QRCode from 'qrcode';
import { Buyer } from '@/api';

export default {
  name: 'StoreHome',
  mixins: [storeMixin],
  data() {
    return {
      storeDetail: {},
      QRCodeSrc: '',
    };
  },
  beforeRouteEnter(to, from, next) {
    Buyer.Sp.ShopStore.getShopStoreDetail()
      .then(json => {
        const res = json.data.data;
        next(vm => {
          vm.storeDetail = res;
          console.log(res);
        });
      })
      .catch(() => next());
  },
  watch: {
    storeDetail(val) {
      if (val.id) return;
      Buyer.Sp.ShopStoreGoodsCat.select();
    },
  },
  methods: {
    onGotoStore() {
      if (!this.storeDetail.id) {
        this.$router.push(`/proxyStore/${this.storeUserName}/decoration`);
        this.$messageBox.tips('必须先设置店铺');
      } else {
        // window.location.href = `/store/#/proxyStore/${this.storeUserName}/index`;
        window.location.href =
          this.$globalVar.shopData.siteUrl +
          `/store?shareUrl=${encodeURIComponent(
            `/proxyStore/${this.storeUserName}/index`,
          )}`;
      }
    },
    onStoreQrcodeOld() {
      QRCode.toDataURL(
        `${
          this.$globalVar.shopData.siteUrl
        }/store?shareUrl=${encodeURIComponent(
          `/proxyStore/${this.storeUserName}/index`,
        )}`,
        {
          margin: 2,
          scale: 30,
          errorCorrectionLevel: 'H',
        },
        (error, url) => {
          if (error) {
            console.error(error);
            return;
          }
          this.$messageBox.alert(
            `<img src="${url}" width="100%"/>`,
            '店铺二维码',
          );
        },
      );
    },
    drawText(ctx, t, x, y, w, h = 99999999, lh = 36) {
      const chr = t.split('');
      let temp = '';
      const row = [];

      // ctx.font = '20px Arial';
      // ctx.fillStyle = 'black';
      // ctx.textBaseline = 'middle';

      for (let a = 0; a < chr.length; a++) {
        if (ctx.measureText(temp).width < w) {
          // 保留
        } else {
          if (row.length + 1 >= h) {
            temp += '...';
            break;
          }
          row.push(temp);
          temp = '';
        }
        temp += chr[a];
      }

      row.push(temp);

      for (let b = 0; b < row.length; b++) {
        ctx.fillText(row[b], x, y + b * lh);
        if (b + 1 >= h) return;
      }
    },
    async onStoreQrcode() {
      if (!this.QRCodeSrc) {
        const canvas = this.$refs.graphicCanvas;
        const ctx = canvas.getContext('2d');
        const qrcode = await this.$utils.Comm.creatQRCode(
          `${
            this.$globalVar.shopData.siteUrl
          }/store?shareUrl=${encodeURIComponent(
            `/proxyStore/${this.storeUserName}/index`,
          )}`,
        );
        // this.storeDetail.storeLogoUrl =
        //     'http://oss.goefresh.ca/upload/sys/siteSetting/77d3b56a39a74a68acaaccb77d60b043.png';
        const imagesList = [qrcode];
        this.storeDetail.storeLogoUrl &&
          imagesList.push(this.storeDetail.storeLogoUrl);
        const imgs = await this.$utils.Comm.loadImgs(imagesList);

        const { width, height } = canvas;

        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, width, height);

        ctx.drawImage(imgs[0], 0, 0, width, width);

        if (imgs[1]) {
          ctx.fillRect(width / 2 - 50, width / 2 - 50, 100, 100);
          ctx.drawImage(imgs[1], width / 2 - 45, width / 2 - 45, 90, 90);
        }

        ctx.font = '40px Helvetica';
        ctx.fillStyle = '#000';
        ctx.textAlign = 'center';
        this.drawText(
          ctx,
          this.storeDetail.storeName,
          width / 2,
          height - 70,
          width,
        );
        ctx.font = '26px Helvetica';
        ctx.fillStyle = '#999';
        this.drawText(
          ctx,
          '长按识别二维码 进入店铺',
          width / 2,
          height - 30,
          width,
        );
        this.QRCodeSrc = canvas.toDataURL();
      }
      this.$messageBox.alert(
        `<img src="${this.QRCodeSrc}" width="100%"/>`,
        '店铺二维码',
      );
    },
    async onBatchEditInput(data) {
      await this.$messageBox.confirm(
        '此功能将初始化店铺中的类目及商品,之前的数据将被清空。是否确认初始化类目及商品数据',
        '温馨提示',
      );
      const {
        data: { msg },
      } = await this.$api.Buyer.Sp.ShopStore.autoInitShopStore(data);
      this.$messageBox.tips(msg);
      console.log(data);
    },
    onEditSendSuccess(data) {
      console.log(data);
      this.storeDetail.sendConsignor = data.sendConsignor;
      this.storeDetail.sendTel = data.sendTel;
    },
  },
  activated() {
    this.updatedCountInfo();
  },
};
</script>

<style lang="scss" scoped>
.tool-bar {
  overflow: hidden;
  > .item {
    position: relative;
    > .price {
      font-size: 120%;
      font-weight: bold;
    }
    > .badge {
      position: absolute;
      right: -$padding;
      top: -$padding;
      border-radius: 0;
      font-size: 75%;
      padding: $padding $padding-big * 2 $padding-small/2 $padding-big * 2;
      transform-origin: top center;
      transform: translate(50%, 0) rotate(45deg);
    }
  }
}
</style>
<style lang="scss">
.achievement-wrap {
  padding: $padding-big;
  background-image: linear-gradient(160deg, #fff -300%, $color-second 150%);
  //background-color: $color-second;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin-bottom: $margin-big;
    > .label,
    > .extra {
      flex: 1 1 1%;
      font-size: 120%;
      font-weight: bold;
    }
    > .extra {
      flex: 0 0 auto;
    }
  }
  > .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    > .item {
      position: relative;
      flex: 1 1 1%;
      color: #fff;
      padding-right: $padding-big;
      &:after {
        position: absolute;
        top: 50%;
        right: $padding;
        content: '';
        display: block;
        height: 50px;
        width: 1px;
        margin-top: -25px;
        opacity: 0.5;
        background-color: $color-border;
      }
      &:last-child {
        padding-right: 0;
        &:after {
          display: none;
        }
      }

      > .label {
        opacity: 0.9;
      }
      > .value {
        font-size: 120%;
        font-weight: bold;
      }
    }
  }
}
</style>

<!-- Created by henian.xu on 2019/3/4. -->

<template>
  <!--<Page>
        <Container ref="container">
            <CategoryTab>
                <CategoryTabPanel
                    v-for="item in 5"
                    :key="item"
                    :label="`label${item}`">
                    <div
                        v-for="i in 30"
                        :key="i">CategoryTabPanel {{ item }}-{{ i }}</div>
                </CategoryTabPanel>
            </CategoryTab>
        </Container>
    </Page>-->
  <Page>
    <ProxyStoreGoodsCatPicker
      class="second-header"
      :data="catList"
      v-model="catId"
      @input="refreshData($event)"
    />
    <query-box
      class="second-header"
      top=".89rem"
      placeholder="请输入商品名称"
      show-search-btn
      v-model="goodsName"
      @query="refreshData($event,true)"
    />
    <Container ref="container">
      <template v-if="catId">
        <List>
          <ProxyStoreEditGoodsItem
            class="ma-b"
            v-for="(item,index) in goodsList"
            :key="item.id"
            :data="item"
            :cat-id="catId"
            is-ctrl
            @delete="onDel(index)"
            @editSuccess="refreshData"
            @click="onItem(item,index)"
            v-model="selectList"
          />
        </List>
        <infinite-loading
          ref="infinite"
          :disabled="infiniteDisabled"
          @infinite="onInfinite"
        />
      </template>
      <ProxyStoreBatchEditGoods
        ref="batchEditPrice"
        :type="2"
        :disabled="!canBatch"
        @input="(data)=>{onBatchEditInput(data)}"
      />
      <ProxyStoreBatchEditGoods
        ref="batchEditCat"
        :type="1"
        :cat-select-list="catSelectList"
        :disabled="!canBatch"
        @input="(data)=>{onBatchEditInput(data)}"
      />
    </Container>
    <PageBar>
      <div class="pa-a dp-fx fx-ai-c">
        <Checkbox
          v-model="selectAll"
        >
          全选
        </Checkbox>
      </div>
      <Button
        :label="`批量操作${selectList.length?'('+selectList.length+')':''}`"
        theme="second"
        :disabled="!canBatch"
        @click="()=>{onBatch()}"
      />
      <!--<ProxyStoreBatchEditGoods
                :cat-select-list="catSelectList"
                :disabled="!canBatch"
                @input="(data)=>{onBatchEditInput(data)}"
            >
                <Button :theme="canBatch?'second':'g5'">
                    批量修改<span v-if="selectList.length">({{ selectList.length }})</span>
                </Button>
            </ProxyStoreBatchEditGoods>-->
      <Button
        label="选择商品"
        theme="main"
        to="./selectGoods"
      />
    </PageBar>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import infiniteLoading from '@/mixins/infiniteLoading';
import { Buyer } from '@/api';

export default {
  name: 'ProxyStoreGoods',
  mixins: [storeMixin, infiniteLoading],
  data() {
    return {
      goodsName: '',
      catList: [],
      catId: 0,
      goodsList: [],
      allGoodsIdList: [],
      selectList: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    Buyer.Sp.ShopStoreGoodsCat.select()
      .then(json => {
        const catList = json.data.data || [];
        next(vm => {
          vm.catList = catList;
        });
      })
      .catch(() => next());
  },
  computed: {
    selectAll: {
      get() {
        const { selectList, allGoodsIdList } = this;
        return (
          (selectList.length || allGoodsIdList.length) &&
          selectList.length === allGoodsIdList.length
        );
      },
      set(val) {
        if (val) {
          this.selectList = this.allGoodsIdList;
        } else {
          this.selectList = [];
        }
      },
    },
    catSelectList() {
      return this.catList.reduce(
        (p, c) => (p.push({ id: c.id, name: c.goodsCatName }), p),
        [],
      );
    },
    canBatch() {
      return !!this.selectList.length;
    },
  },
  methods: {
    getAgentGoodsIdList() {
      this.$api.Buyer.Sp.ShopStoreGoods.agentGoodsIdList({
        goodsName: this.goodsName,
        isAgent: 1,
        goodsCatId: this.catId,
      }).then(json => {
        const res = json.data;
        this.allGoodsIdList = res.data;
      });
    },
    getListData({ currentPage, pageSize } = {}) {
      return this.$api.Buyer.Sp.ShopStoreGoods.agentGoodsList({
        goodsName: this.goodsName,
        isAgent: 1,
        goodsCatId: this.catId,
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.goodsList = this.goodsList.concat(res.data);
        return Promise.resolve(json);
      });
    },
    refreshData($event, search = false) {
      !search && (this.goodsName = '');
      this.infiniteDisabled = true;
      this.pagination = {};
      this.selectList = [];
      this.allGoodsIdList = [];
      this.goodsList = [];
      this.getAgentGoodsIdList();
      this.$nextTick(() => (this.infiniteDisabled = false));
    },
    onDel(index) {
      this.goodsList.splice(index, 1);
    },
    onItem(item) {
      const { selectList } = this;
      const index = selectList.indexOf(item.id);
      if (index === -1) {
        this.selectList.push(item.id);
      } else {
        this.selectList.splice(index, 1);
      }
    },
    // 批量操作处理
    async onBatchEditInput(data) {
      data = {
        ...data,
        ids: this.selectList.join(','),
      };
      // if (!data.addPrice) return;
      console.log(data);
      const { goodsCatIds } = data;
      let json;
      if (goodsCatIds.length) {
        data.goodsCatIds = goodsCatIds.join(',');
        json = await this.$api.Buyer.Sp.ShopStoreGoods.batchEditAgentGoodsRelCat(
          data,
        );
      } else {
        json = await this.$api.Buyer.Sp.ShopStoreGoods.batchEditAgentGoods(
          data,
        );
      }
      this.refreshData(null, true);
      this.selectList = [];
      this.$messageBox.tips(json.data.msg, 3000);
    },
    async onBatchCancelProxy() {
      console.log('onBatchCancelProxy');
      await this.$messageBox.confirm('确定要全部取消代理', '温馨提示');
      const {
        data: { msg },
      } = await this.$api.Buyer.Sp.ShopStoreGoods.cancelAgentGoods({
        ids: this.selectList.join(','),
      });
      this.refreshData();
      this.$messageBox.tips(msg);
    },
    async onBatch() {
      const { id } = await this.$messageBox.action({
        actionList: [
          {
            id: 1,
            name: '批量改价',
          },
          {
            id: 2,
            name: '批量修改类目',
          },
          {
            id: 3,
            name: '批量取消代理',
            color: '#fff',
            backgroundColor: '#f00',
          },
        ],
      });
      console.log(id);
      switch (id) {
        case 1:
          this.$refs['batchEditPrice'].open();
          break;
        case 2:
          this.$refs['batchEditCat'].open();
          break;
        case 3:
          this.onBatchCancelProxy();
          break;
        default:
          break;
      }
    },
  },
  activated() {
    this.refreshData();
  },
};
</script>

<style lang="scss">
</style>

<!-- Created by henian.xu on 2019/3/4. -->

<template>
  <Page>
    <Container ref="container">
      <list>
        <ProxyStoreCategoryItem
          v-for="(item,index) in dataList"
          :key="item.id"
          :data="item"
          @del="onDel(index)"
        />
      </list>
      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      />
    </Container>
    <PageBar>
      <ProxyStoreAddCategory @update="refreshData">
        <Button
          label="添加类目"
          theme="main"
        />
      </ProxyStoreAddCategory>
    </PageBar>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'ProxyStoreCategory',
  mixins: [storeMixin, infiniteLoading],
  data() {
    return {
      dataList: [],
    };
  },
  methods: {
    getListData({ currentPage, pageSize } = {}) {
      return this.$api.Buyer.Sp.ShopStoreGoodsCat.data({
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.dataList = this.dataList.concat(res.data);
        return Promise.resolve(json);
      });
    },
    onDel(index) {
      console.log(index);
      this.dataList.splice(index, 1);
    },
    refreshData() {
      this.infiniteDisabled = true;
      this.pagination = {};
      this.dataList = [];
      this.$nextTick(() => {
        this.infiniteDisabled = false;
      });
    },
  },
};
</script>

<style lang="scss">
</style>

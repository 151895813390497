<!-- Created by henian.xu on 2019/3/4. -->

<template>
  <Page>
    <div class="second-header">
      <tabs @tabChange="onTabsChange">
        <template v-for="(item,index) in orderStatusMap">
          <tab-panel
            v-if="!(!$hasPower(['groupBuy:use']) && +item.status === 15)"
            :key="index"
            :label="item.name"
            :sign="item.status"
            :active-panel="item.status+'' === orderStatus+''"
          >
            <div slot="nav">
              <i
                class="f-icon"
                v-if="+item.status === 10"
              >&#xf050;</i>
              <i
                class="f-icon"
                v-if="+item.status === 20"
              >&#xf051;</i>
              <i
                class="f-icon"
                v-if="+item.status === 30"
              >&#xf052;</i>
              <i
                class="f-icon"
                v-if="+item.status === 99"
              >&#xf054;</i>
              <i
                class="f-icon"
                v-if="+item.status === 100"
              >&#xf053;</i>
              {{ item.name }}
            </div>
          </tab-panel>
        </template>
      </tabs>
    </div>
    <query-box
      class="second-header"
      top=".89rem"
      placeholder="请输入姓名或手机号"
      show-search-btn
      multiple
      v-model="keywords"
      @query="onSearch"
      @reset="onReset"
    >
      <x-form
        ref="form"
        :model="forModel"
      >
        <form-item
          label="订单号:"
          prop="orderSn"
        >
          <x-input v-model="forModel.orderSn" />
        </form-item>
        <form-item
          label="下单人电话:"
          prop="buyerMobile"
        >
          <x-input v-model="forModel.buyerMobile" />
        </form-item>
        <form-item
          label="收货人姓名:"
          prop="deliveryConsignee"
        >
          <x-input v-model="forModel.deliveryConsignee" />
        </form-item>
        <form-item
          label="收货人电话:"
          prop="deliveryPhone"
        >
          <x-input v-model="forModel.deliveryPhone" />
        </form-item>
      </x-form>
    </query-box>
    <Container ref="container">
      <List>
        <StoreOrderItem
          v-for="(item,index) in orderList"
          :key="item.id"
          :data="item"
          @delete="onItemDelete(item,index)"
          @update="onUpdate($event,index)"
        />
      </List>

      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      />
    </Container>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'ProxyStoreOrderList',
  mixins: [storeMixin, infiniteLoading],
  data() {
    return {
      orderStatusMap: [
        {
          status: 0,
          name: '全部',
        },
        {
          status: 10,
          name: '待付款',
        },
        {
          status: 20,
          name: '待发货',
        },
        {
          status: 30,
          name: '待收货',
        },
        {
          status: 100,
          name: '已完成',
        },
        {
          status: 99,
          name: '已取消',
        },
      ],
      orderList: [],
      keywords: '',
      forModel: {
        orderSn: '',
        buyerMobile: '',
        deliveryConsignee: '',
        deliveryPhone: '',
      },
    };
  },
  computed: {
    orderStatus() {
      return +this.$route.params.orderStatus || 0;
    },
  },
  methods: {
    getListData({
      orderStatus = this.orderStatus,
      currentPage,
      pageSize,
    } = {}) {
      return this.$api.Buyer.Sp.ShopStoreOrder.orderList({
        ...this.forModel,
        searchKeyword: this.keywords,
        orderStatus,
        shipMethod: 1,
        isStoreAgentSell: true,
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.orderList = this.orderList.concat(res.data);
        return Promise.resolve(json);
      });
    },
    refreshList() {
      this.infiniteDisabled = true;
      this.pagination = {};
      this.orderList = [];
      this.$nextTick(() => {
        this.infiniteDisabled = false;
      });
    },
    onTabsChange(item) {
      this.$router.replace(
        {
          ...this.$route,
          params: {
            orderStatus: item.sign,
          },
        },
        this.refreshList,
      );
    },
    onUpdate(data, index) {
      this.orderList.splice(index, 1, data);
    },
    onItemDelete(item, index) {
      this.orderList.splice(index, 1);
    },
    onSearch(event, quick) {
      if (quick) {
        this.onReset();
      } else {
        this.keywords = '';
      }
      this.$nextTick(() => this.refreshList());
    },
    onReset() {
      this.$$form.resetFields();
      this.$$form.clearValidate();
    },
  },
};
</script>

<style lang="scss">
</style>
